import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import ProjectsData from "../../data/projects"
import InnerLayout from "../../components/InnerLayout"
import InnerWrapper from "../../components/InnerWrapper"
import ProjectHero from "../../components/project/Hero"
import ProjectWrapper from "../../components/project/Wrapper"
import ProjectIntro from "../../components/project/Intro"
import ProjectSection from "../../components/project/Section"
import ProjectNext from "../../components/project/Next"

import bakobaRecordingOne from '../../images/bakoba/bakoba-float-recording.mp4'
import bakobaRecordingTwo from '../../images/bakoba/bakoba-filtering-recording.mp4'

const Bakoba = (pageContext) => {
  const projectsData = ProjectsData();
  const projectId = 4;
  const projectData = projectsData.find(data => data.id === projectId);

  const images = useStaticQuery(graphql`
    query {
      bakobaMockupOne: file(relativePath: { eq: "bakoba/bakoba-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bakobaMockupTwo: file(relativePath: { eq: "bakoba/bakoba-shop-en-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bakobaMockupThree: file(relativePath: { eq: "bakoba/bakoba-guide-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bakobaImage: file(relativePath: { eq: "bakoba/bakoba-image.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const contentSectionOne = (
    <div>
      <h3>Dynamic and playful website with eCommerce</h3>
      <p>A joyful brand universe informing visitors of the unique qualities of the toy and enabling customers to order straight from the webshop or find inspiration.</p>
      <ul className="list-unstyled"><li>- Engaging video content</li><li>- Clear commercial calls to action</li><li>- eCommerce capabilities</li><li>- Multi-currency</li><li>- English and Danish languages</li><li>- Build guide finder module</li><li>- API integration with payment, delivery and ERP software</li><li>- Shop/retail locator</li><li>- Distributor overview and application form</li><li>- Focus on educational benefits</li><li>- CMS integration for easy managing of content and products</li></ul>
    </div>
  );

  return (
    <InnerLayout noTopPadding>
      <SEO title="BAKOBA project" bodyClass="ProjectPage" />
      <ProjectHero 
        title={projectData.title}
        subtitle={projectData.excerpt}
        color={projectData.color}
        image={projectData.image}
      />
      <InnerWrapper>
        <ProjectWrapper>
          <ProjectIntro
            client={projectData.client}
            website={projectData.website}
            agency={projectData.agency}
            year={projectData.year}
            category={projectData.category}
            role={projectData.role}
            technologies={projectData.technologies}
            problem="BAKOBA is a Copenhagen based toy startup redefining the classic building blocks for kids around the world. Through their construction toy they want to give all children the experience and joy of unlimited creativity. A new website needed to reflect the creativity and joy that BAKOBA brings to children while enabling online sales and providing a strong platform to attract new retailers and distributors."
            solution="In close cooperation with the startup we created a playful online brand and eCommerce platform enabling BAKOBA to sell their products to children and their parents worldwide. Video, colors and clever use of the toy building blocks helps to tell the story of BAKOBA and the endless possibilities of creation it enables. A building guide module enables customers to easily pick their products and then it automatically figures out which figures can be build from this combination of blocks, if a bit of inspiration is needed."
          />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.bakobaMockupOne.childImageSharp.fluid]}
          />
          <ProjectSection
            type="default"
            bgcolor="white"
            video={bakobaRecordingOne}
            fullwidth
          />
          <ProjectSection
            type="image-columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.bakobaMockupTwo.childImageSharp.fluid, images.bakobaMockupThree.childImageSharp.fluid]}
            reversed
          />
          <ProjectSection
            type="default"
            bgcolor="white"
            video={bakobaRecordingTwo}
          />
          <ProjectSection
            bgcolor="white"
            images={[images.bakobaImage.childImageSharp.fluid]}
            fullwidth
          />
          <ProjectNext project={4} />
        </ProjectWrapper>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default Bakoba